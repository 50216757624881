<template>
  <div class="container" ref="container">
    <headerTop
      @handleChangeTime="handleChangeTime"
      @handleChangeSchool="handleChangeSchool"
      :value="value"
      title="教师综合评价"
    ></headerTop>

    <div class="flexSb header">
      <div class="header_box1 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ allInfo.teacher_count ? allInfo.teacher_count : "- -" }}
            <span v-if="allInfo.teacher_count">人</span>
          </div>
          <div class="num_title">体育教师人数</div>
        </div>
        <img class="header_img" src="@/assets/img/152.png" alt="" />
        <div @click="handleTo('/teacherlist')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box2 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{
              allInfo.sports_lesson_attendance_rate
                ? allInfo.sports_lesson_attendance_rate
                : "- -"
            }}
            <span v-if="allInfo.sports_lesson_attendance_rate">%</span>
          </div>
          <div class="num_title">体育课出勤率</div>
        </div>
        <img class="header_img" src="@/assets/img/159.png" alt="" />
        <div
          @click="handleTo('/teacherAttendanceRecords')"
          class="detail flexSc"
        >
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box3 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{
              allInfo.big_break_attendance_rate
                ? allInfo.big_break_attendance_rate
                : "- -"
            }}
            <span v-if="allInfo.big_break_attendance_rate">%</span>
          </div>
          <div class="num_title">大课间出勤率</div>
        </div>
        <img class="header_img" src="@/assets/img/161.png" alt="" />
        <div
          @click="handleTo('/teacherAttRecordsbreaktime')"
          class="detail flexSc"
        >
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box4 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{
              allInfo.teaching_lesson_plans
                ? allInfo.teaching_lesson_plans
                : "- -"
            }}
            <span v-if="allInfo.teaching_lesson_plans">份</span>
          </div>
          <div class="num_title">教学教案</div>
        </div>
        <img class="header_img" src="@/assets/img/158.png" alt="" />
        <div @click="handleTo('/teachingpreparation')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box5 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ allInfo.competition_count ? allInfo.competition_count : "- -" }}
            <span v-if="allInfo.competition_count">个</span>
          </div>
          <div class="num_title">竞赛成果</div>
        </div>
        <img class="header_img" src="@/assets/img/160.png" alt="" />
        <div @click="handleTo('/sportsAwards')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box1 flexSb">
      <div class="box1_left">
        <div class="box1_title">教师情况</div>
        <div class="flexSb">
          <div class="echart_box" ref="sex_ratio"></div>
          <div class="echart_box" ref="distributionexport"></div>
          <div class="echart_box" ref="speciality"></div>
          <div class="echart_box" ref="teachingStyle"></div>
          <div class="echart_box" ref="teachingExperience"></div>
        </div>
      </div>

      <div class="box1_right">
        <div class="box1_title">综合评价</div>
        <div class="evaluValue">
          <div>
            {{ OverviewInfo.score ? OverviewInfo.score : "- -" }}
            <span>分</span>
          </div>
          <div>{{ OverviewInfo.type ? OverviewInfo.type : "- -" }}</div>
        </div>
        <div class="evaluation" ref="evaluation"></div>
      </div>
    </div>

    <div class="box2">
      <div class="box2_title flexSb">
        <div>教师体育课出勤情况</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="attendance" ref="attendance_Class"></div>
        <!-- 全区 -->
        <div
          v-show="isAllSchool"
          id="attendanceStudentAll"
          class="attendanceStudent"
        >
          <div
            class="attendanceStudent_item"
            ref="attendance_Class_studentAll"
          ></div>
        </div>

        <!-- 单个学校 -->

        <div
          v-show="!isAllSchool"
          id="attendanceStudent"
          class="attendanceStudent"
        >
          <div
            v-show="!isAllSchool"
            class="attendanceStudent_item"
            ref="attendance_Class_student"
          ></div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2_title flexSb">
        <div>教师大课间出勤情况</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="attendance" ref="recess"></div>

        <!-- 全区 -->
        <div
          v-show="isAllSchool"
          id="recessStudentAll"
          class="attendanceStudent"
        >
          <div
            class="attendanceStudent_item"
            ref="recess_student_studentAll"
          ></div>
        </div>

        <!-- 单个学校 -->
        <div v-show="!isAllSchool" id="recessStudent" class="attendanceStudent">
          <div class="attendanceStudent_item" ref="recess_student"></div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2_title flexSb">
        <div>教学教案变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="attendance" ref="teaching_plans"></div>
        <!-- 全区 -->
        <div v-show="isAllSchool" id="teachingAll" class="attendanceStudent">
          <div
            class="attendanceStudent_item"
            ref="teaching_plans_studentAll"
          ></div>
        </div>

        <!-- 单个学校 -->
        <div v-show="!isAllSchool" id="teaching" class="attendanceStudent">
          <div
            class="attendanceStudent_item"
            ref="teaching_plans_student"
          ></div>
        </div>
      </div>
    </div>

    <div class="box1 box3 flexSb attendance3">
      <div class="box1_left">
        <div class="box1_title flexSb">
          <div>竞赛参与情况</div>
          <div @click="handleTo('/sportsCompetition')" class="more">More+</div>
        </div>

        <div class="tabbox">
          <!-- stripe    :row-class-name="tableRowClassName"-->
          <el-table
            :header-cell-style="{
              fontSize: '16px',
              color: '#333333',
              paddingTop: '8px',
              paddingBottom: '8px',
            }"
            stripe
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column label="缩略图" width="120" align="center">
              <template slot-scope="scope">
                <img class="img_a" src="@/assets/img/162.png" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="标题" align="center">
            </el-table-column>
            <el-table-column prop="address" label="参与教师人数" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="竞赛时间"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table>
          <!-- <div class="flexSe elpla">
            <el-pagination background layout="prev, pager, next" :total="1000">
            </el-pagination>
          </div> -->
        </div>
      </div>

      <div class="box1_right">
        <div class="box1_title">
          {{ isAllSchool ? "竞赛辅导排行" : "竞赛获奖排行" }}
        </div>
        <el-table
          :header-cell-style="{
            fontSize: '16px',
            color: '#333333',
            paddingTop: '8px',
            paddingBottom: '8px',
          }"
          :data="tableData2"
          stripe
          style="width: 100%"
        >
          <el-table-column width="120">
            <template slot-scope="scope">
              <img
                @click="handleToDeatail(scope.row)"
                class="img_a_im2"
                src="@/assets/img/1633.png"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="排名" align="center">
            <template slot-scope="scope">
              <img
                v-if="scope.row.rank == 1"
                class="img_a_im"
                src="@/assets/img/164.png"
                alt=""
              />
              <img
                v-if="scope.row.rank == 2"
                class="img_a_im"
                src="@/assets/img/165.png"
                alt=""
              />
              <img
                v-if="scope.row.rank == 3"
                class="img_a_im"
                src="@/assets/img/166.png"
                alt=""
              />

              <div v-if="scope.row.rank > 3">
                {{ scope.row.rank }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isAllSchool" label="姓名" align="center">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="handleToDeatail(scope.row)">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else label="教师姓名" align="center">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="handleToDeatail(scope.row)">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="isAllSchool" label="学校" align="center">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="handleToDeatail(scope.row)">
                测试学校
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="学生获奖次数"
            align="center"
            width="120"
            fixed="right"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="box2">
      <div class="box2_title flexSb">
        <div>体质健康双达标变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div id="health" class="flexSb">
        <div class="attendance attendance2" ref="health"></div>
        <!-- 学校隐藏 -->
        <!-- <div class="attendanceStudent" ref="health_student"></div> -->
        <!-- 全区展示 -->
        <div
          v-show="isAllSchool"
          class="attendanceStudent"
          ref="health_student_All"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  sex_ratio,
  distributionexport,
  Specialityexport,
  teaching_style,
  teaching_experience,
  evaluation,
  attendance_Class,
  attendance_Class_student,
  attendance_Class_studentAll,
  recess,
  recessStudent,
  recessStudentAll,
  teachingPlans,
  teachingPlansStudent,
  teachingPlansStudentAll,
  health,
  health_student,
  health_student_All,
} from "../enums/evaluation";
import * as echarts from "echarts";
import headerTop from "../../components/newheaderTop/index.vue";
// import {
//   teachersOverviewPhysicalHealth,
//   teachersOverviewStatistics,
//   teachersOverviewOverview,
//   teachersOverviewSituation,
// } from "@/api/evaluation";
export default {
  components: {
    headerTop,
  },
  data() {
    return {
      OverviewInfo: {
        score: "",
        type: "",
        info: [],
      },
      allInfo: {
        big_break_attendance_rate: 0,
        competition_count: 0,
        sports_lesson_attendance_rate: 0,
        teacher_count: 0,
        teaching_lesson_plans: 0,
      },
      startTime: "",
      endTime: "",
      yearName: "",
      options: [],
      value: "",
      sexRatio: sex_ratio,
      distributionexport: distributionexport,
      Speciality: Specialityexport,
      teachingStyle: teaching_style,
      teachingExperience: teaching_experience,
      evaluationOptions: evaluation,
      attendanceClass: attendance_Class,
      attendanceClassStudent: attendance_Class_student,
      attendanceClassStudentAll: attendance_Class_studentAll,
      recess: recess,
      recessStudent: recessStudent,
      recessStudentAll: recessStudentAll,
      teachingPlans: teachingPlans,
      teachingPlansStudent: teachingPlansStudent,
      teachingPlansStudentAll: teachingPlansStudentAll,
      health: health,
      health_student: health_student,
      health_student_All: health_student_All,

      tableData: [],
      tableData2: [
        // {
        //   rank: "1",
        //   name: "校运会",
        //   address: "10人",
        // },
        // {
        //   rank: "2",
        //   name: "校运会",
        //   address: "10人",
        // },
        // {
        //   rank: "3",
        //   name: "校运会",
        //   address: "10人",
        // },
        // {
        //   rank: "4",
        //   name: "校运会",
        //   address: "10人",
        // },
        // {
        //   rank: "5",
        //   name: "校运会",
        //   address: "10人",
        // },
        // {
        //   rank: "5",
        //   name: "校运会",
        //   address: "10人",
        // },
      ],
      startTime: "",
      endTime: "",
      yearName: "",
      teacherID: "",
      isAllSchool: true,
    };
  },
  watch: {
    $route: function (to, from) {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;
    },

    isAllSchool(v) {
      // 在show为true，也就是显示的时候，调用resize 解决100px的问题
      if (v) {
        this.handleAllAchool();
      } else {
        this.$nextTick(() => {
          let fuHeight =
            document.getElementById("attendanceStudent").clientHeight + "px";
          let fuWidth =
            document.getElementById("attendanceStudent").clientWidth + "px";

          let fuHeight2 = document.getElementById("health").clientHeight + "px";
          let fuWidth2 = document.getElementById("health").clientWidth + "px";

          this.$refs.attendance_Class_student.style.height = fuHeight;
          this.$refs.attendance_Class_student.style.width = fuWidth;

          this.$refs.recess_student.style.height = fuHeight;
          this.$refs.recess_student.style.width = fuWidth;

          this.$refs.teaching_plans_student.style.height = fuHeight;
          this.$refs.teaching_plans_student.style.width = fuWidth;

          this.$refs.health.style.height = fuHeight;
          this.$refs.health.style.width = fuWidth;

          this.handleSingleSchool();
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$forceUpdate();
      window.scrollTo(0, 0);

      this.handleGetTeachersOverviewOverview();
      this.handleGetTeachersOverviewSituation();

      this.handleInitDistributionexport();
      this.handleInitSpecialityexport();
      this.handleInitTeachingStyle();
      this.handleInitTeachingexperience();

      this.handleInitattendanceClass();
      if (this.isAllSchool) {
        this.handleAllAchool();
      } else {
        this.handleSingleSchool();
      }
      this.handleInitrecess();
      // this.handleInitrecesStudent();
      this.handleInitteachingplans();

      // 对接口后删除
      // this.handleInithealthStudent();
      this.handleInithealthStudentAll();
    });
  },
  methods: {
    handleAllAchool() {
      this.handleInitattendanceClassStudentAll();
      this.handleInitrecesStudentAll();
      this.handleInitteachingplansStudentAll();
      this.handleInithealth();
    },
    handleSingleSchool() {
      this.handleInitattendanceClassStudent();
      this.handleInitrecesStudent();
      this.handleInitteachingplansStudent();
      this.handleInithealth();
    },
    handleChangeSchool(val) {
      this.schoolName = val.schoolName;
      this.schoolID = val.schoolID;

      // 是否是全区
      this.$nextTick(() => {
        if (val.schoolID == -1) {
          this.isAllSchool = true;
          console.log(111111111111);
        } else {
          this.isAllSchool = false;
          console.log(2222222222222);
        }
      });

      console.log(this.isAllSchool, val.schoolID, "val.schoolID");
    },
    handleChangeTime(val) {
      this.startTime = val.startTime;
      this.endTime = val.endTime;
      this.yearName = val.value;

      this.handleGetteachersOverviewPhysicalHealth();
      this.handleTeachersOverviewStatistics();
    },
    async handleGetTeachersOverviewSituation() {
      // await teachersOverviewSituation().then((res) => {
      //   console.log(res);
      //   let people_rate = res.data.people_rate;
      //   let sex_ratioData = [
      //     { value: people_rate.men_rate, name: "男" },
      //     { value: people_rate.women_rate, name: "女" },
      //   ];
      //   this.sexRatio.legend.formatter = function (name) {
      //     // 在这里编写自定义的格式化逻辑
      //     let value;
      //     for (let i = 0; i < sex_ratioData.length; i++) {
      //       // value = grade_ratioData[i].value;
      //       // console.log(i, name);
      //       if (name == sex_ratioData[i].name) {
      //         return name + sex_ratioData[i].value + "%";
      //       }
      //     }
      //   };

      //   this.sexRatio.series[0].data = sex_ratioData;

      this.handleInitSexRatio();
      // });
    },
    async handleGetTeachersOverviewOverview() {
      // await teachersOverviewOverview().then((res) => {
      //   this.OverviewInfo = res.data;
      //   let info = res.data.info;
      //   this.evaluationOptions.series[0].data = [];
      //   info.forEach((item, index) => {
      //     this.evaluationOptions.series[0].data.push({
      //       value: item,
      //       name: index,

      //       label: {
      //         normal: {
      //           show: true,
      //           color: this.evaluationOptions.color[index],
      //           formatter: function (params) {
      //             return params.value;
      //           },
      //         },
      //       },
      //       areaStyle: {
      //         color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
      //           {
      //             color: this.evaluationOptions.color[index],
      //             offset: 0,
      //           },
      //           {
      //             color: this.evaluationOptions.color[index],
      //             offset: 1,
      //           },
      //         ]),
      //       },
      //     });
      //   });
      //   console.log(this.evaluationOptions, evaluation);
      this.handleInitevaluation();
      // });
    },
    async handleTeachersOverviewStatistics() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await teachersOverviewStatistics(data).then((res) => {
        console.log(res);
        this.allInfo = res.data;
      });
    },
    async handleGetteachersOverviewPhysicalHealth() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      // await teachersOverviewPhysicalHealth(data).then((res) => {
      //   let val = res.data;
      //   let year = [];
      //   let excellent = val.tree_diagram.excellent;
      //   let pass = val.tree_diagram.pass;
      //   val.tree_diagram.year.forEach((item, index) => {
      //     year.push([
      //       item,
      //       excellent[index] ? excellent[index] : 0,
      //       pass[index] ? pass[index] : 0,
      //     ]);
      //   });
      //   this.health.dataset.source = [["product", "优秀率", "合格率"], ...year];
      this.handleInithealth();

      // this.health_student.title = "体质健康双达标分布图" + this.yearName;

      // this.handleInithealthStudent();
      // console.log(
      //   this.health.dataset.source[0],
      //   "=  this.health.dataset.source[0] "
      // );
      // });
    },
    handleGetteachersInfo() {},
    handleTo(url) {
      this.$router.push(url);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        //=>这里可以改成 rowIndex%2=== 1，后面直接else即可达到隔行变色效果。
        return "warning-row";
      } else {
        return "success-row";
      }
      return "";
    },
    handleToDeatail(val) {
      console.log(val);
      this.$router.push("/teacherevaluationdetail");
    },
    handleInitevaluation() {
      let chartDom = this.$refs.evaluation;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.evaluationOptions);
    },
    handleInitSexRatio() {
      let chartDom = this.$refs.sex_ratio;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.sexRatio);
    },
    handleInitDistributionexport() {
      let chartDom = this.$refs.distributionexport;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.distributionexport);
    },
    handleInitSpecialityexport() {
      let chartDom = this.$refs.speciality;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.Speciality);
    },
    handleInitTeachingStyle() {
      let chartDom = this.$refs.teachingStyle;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.teachingStyle);
    },
    handleInitTeachingexperience() {
      let chartDom = this.$refs.teachingExperience;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.teachingExperience);
    },
    handleInitattendanceClass() {
      let chartDom = this.$refs.attendance_Class;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.attendanceClass);
    },
    handleInitattendanceClassStudent() {
      let chartDom = this.$refs.attendance_Class_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.attendanceClassStudent);
    },
    handleInitattendanceClassStudentAll() {
      let chartDom = this.$refs.attendance_Class_studentAll;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.attendanceClassStudentAll);
    },
    handleInitrecess() {
      let chartDom = this.$refs.recess;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.recess);
    },
    handleInitrecesStudent() {
      let chartDom = this.$refs.recess_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.recessStudent);
    },
    handleInitrecesStudentAll() {
      let chartDom = this.$refs.recess_student_studentAll;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.recessStudentAll);
    },
    handleInitteachingplans() {
      let chartDom = this.$refs.teaching_plans;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.teachingPlans);
    },
    handleInitteachingplansStudent() {
      let chartDom = this.$refs.teaching_plans_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.teachingPlansStudent);
    },
    handleInitteachingplansStudentAll() {
      let chartDom = this.$refs.teaching_plans_studentAll;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.teachingPlansStudentAll);
    },

    handleInithealth() {
      console.log(1111);
      let chartDom = this.$refs.health;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.health);
    },
    handleInithealthStudent() {
      let chartDom = this.$refs.health_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.health_student);
    },
    handleInithealthStudentAll() {
      let chartDom = this.$refs.health_student_All;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.health_student_All);
    },
  },
};
</script>
<style lang="less" scoped>
body {
  overflow: hidden;
}
.container {
  min-height: 100%;
  overflow: scroll;
}
/deep/.cell {
  color: #202020 !important;
}
.elpla {
  margin-top: 18px;
  position: absolute;
  bottom: 0;
  right: 20px;
}
.box2 {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  margin-top: 16px;
  .box2_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0 22px;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
  }
  .attendance {
    // width: 70%;
    width: 100%;
    height: 332px;
  }
  .attendanceStudent {
    width: 29%;
    height: 332px;
  }
  .attendanceStudent_item {
    width: 100%;
    height: 332px;
  }
}
.box1 {
  margin-top: 16px;
  .box1_left {
    width: 70%;
    height: 350px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    box-sizing: border-box;
    .echart_box {
      width: 19%;
      height: 300px;
    }
  }
  .box1_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0 22px;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
    .more {
      color: rgba(134, 134, 134, 1);
    }
    /deep/.more:hover {
      color: #226bff; /* 鼠标放上去的颜色 */
      cursor: pointer;
    }
  }
  .box1_right {
    width: 29%;
    height: 350px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);

    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    box-sizing: border-box;
    position: relative;
    .evaluation {
      height: 257px;
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
    }
    .evaluValue {
      position: absolute;
      top: 68px;
      left: 20px;
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
  }
}
.box3 {
  height: 500px;
  .box1_left {
    height: 500px;
  }
  .box1_right {
    height: 500px;
  }
}
.header_title {
  color: rgba(16, 16, 16, 1);
  font-size: 26px;
}
.header {
  margin-top: 22px;
  .posi {
    position: relative;
    .detail {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 35px;
      background-color: rgba(21, 145, 165, 1);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      border-radius: 0 0 10px 10px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      z-index: 99;
      .img_det {
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
    }
  }
  .header_box1 {
    cursor: pointer;
    box-sizing: border-box;
    height: 138px;
    width: 19.5%;
    border-radius: 10px;
    background-color: rgba(23, 162, 184, 1);
    padding: 0 0 0 30px;
    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    .num {
      color: rgba(255, 255, 255, 1);
      font-size: 35px;
      margin-bottom: 6px;
      padding-top: 23px;
      box-sizing: border-box;
      span {
        font-size: 18px;
      }
    }
    .num_title {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      box-sizing: border-box;
      padding-bottom: 39px;
    }
    .header_img {
      width: 125px;
      height: 125px;
      position: absolute;
      right: 0;
    }
  }
  .header_box4 {
    background-color: #dc3545;
    .detail {
      background-color: rgba(198, 48, 62, 1);
    }
  }
  .header_box3 {
    background-color: #ffc107;
    .detail {
      background-color: rgba(229, 173, 6, 1);
    }
  }
  .header_box2 {
    background-color: #28a745;
    .detail {
      background-color: rgba(36, 150, 62, 1);
    }
  }
  .header_box5 {
    background-color: #7f63f2;
    .detail {
      background-color: #6644f3;
    }
  }
}
.more {
  color: rgba(134, 134, 134, 1);
}
/deep/.more:hover {
  color: #226bff; /* 鼠标放上去的颜色 */
  cursor: pointer;
}
.img_a {
  width: 65px;
  height: 47px;
  margin: 0 auto;
  // margin-left: 15px;
}
.rowMa {
  color: pink !important;
}
.img_a_im {
  width: 24px;
  height: 31px;
  margin: 0 auto;
}
.img_a_im2 {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  cursor: pointer;
}
.box1_right {
  /deep/ .el-table td.el-table__cell,
  /deep/.el-table th.el-table__cell.is-leaf {
    // border-bottom: 0 !important;
  }
  // /deep/.el-table--border::after,
  // /deep/ .el-table--group::after,
  // /deep/.el-table::before,
  /deep/.el-table__fixed-right::before,
  /deep/ .el-table__fixed::before {
    // background-color: #fff;
  }
}
.attendance3 {
  /deep/.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    .cell {
      color: #226bff !important;
    }
  }
}
.box3 {
  /deep/.el-table .el-table__cell {
    padding: 4px 0;
  }
  .box1_right {
    /deep/.el-table .el-table__cell {
      padding: 8px 0;
    }
  }
}

/deep/.box1_left {
  .tabbox {
    padding: 0 21px;
    box-sizing: border-box;
    position: relative;
    height: 430px;
  }
  .el-table .warning-row {
    background: #fff; //这里可以修改颜色
  }

  .el-table .success-row {
    background: #f2f2f2; //这里可以修改颜色
  }
}
.box1_right {
  // ::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 10px !important;
  //   background-color: #ccc;
  // }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
.aaaa {
  z-index: 99;
}
.attendance2 {
  flex: 1;
}
</style>
