import * as echarts from "echarts";
var sex_ratioData = [
  { value: 0, name: "男" },
  { value: 0, name: "女" },
];
export const sex_ratio = {
  title: {
    text: "男女比例",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  legend: {
    bottom: "30%",
    left: "center",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < sex_ratioData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == sex_ratioData[i].name) {
          return name + sex_ratioData[i].value + "%";
        }
      }
    },
  },
  series: [
    {
      name: "男女比例",
      type: "pie",
      radius: ["50%", "70%"],
      center: ["50%", "35%"],
      avoidLabelOverlap: false,
      color: ["#5D77F9", "#FF46B3"],
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      labelLine: {
        show: false,
      },
      data: sex_ratioData,
    },
  ],
};
var distributionexportData = [
  { value: 0, name: "大专" },
  { value: 0, name: "本科" },
  { value: 0, name: "研究生" },
];
export const distributionexport = {
  title: {
    text: "学历分布",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}人`;
    },
  },
  legend: {
    bottom: "20%",
    left: "center",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < distributionexportData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == distributionexportData[i].name) {
          return name + "(" + distributionexportData[i].value + ")";
        }
      }
    },
    // width: "10",
  },
  series: [
    {
      name: "学历分布",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: distributionexportData,
      color: ["#5D77F9", "#84B7F9", "#5F9CF8"],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

var SpecialityexportData = [
  { value: 0, name: "篮球" },
  { value: 0, name: "足球" },
  { value: 0, name: "排球" },
];
export const Specialityexport = {
  title: {
    text: "专业特长",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}人`;
    },
  },
  legend: {
    bottom: "20%",
    left: "center",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < SpecialityexportData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == SpecialityexportData[i].name) {
          return name + "(" + SpecialityexportData[i].value + ")";
        }
      }
    },
    // width: "10",
  },
  series: [
    {
      name: "专业特长",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: SpecialityexportData,
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const teaching_style = {
  title: {
    text: "师德师风",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    tooltip: {
      trigger: "item",
    },
  },
  legend: {
    bottom: "20%",
    left: "center",
    // width: "10",
  },
  series: [
    {
      name: "师德师风",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: [
        { value: 0, name: "数据1" },
        { value: 0, name: "数据2" },
        { value: 0, name: "数据3" },
      ],
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },

      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

var teaching_experienceData = [
  { value: 0, name: "数据1" },
  { value: 0, name: "数据2" },
  { value: 0, name: "数据3" },
];
export const teaching_experience = {
  title: {
    text: "教龄分布",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}人`;
    },
  },
  legend: {
    bottom: "20%",
    left: "center",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < teaching_experienceData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == teaching_experienceData[i].name) {
          return name + "(" + teaching_experienceData[i].value + ")";
        }
      }
    },
    // width: "10",
  },
  series: [
    {
      name: "教龄分布",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: teaching_experienceData,
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const evaluation = {
  radar: {
    // shape: 'circle',
    // startAngle: 60,
    indicator: [
      { name: "师德师风", max: 120 },
      { name: "工作能力", max: 120 },
      { name: "工作业绩", max: 120 },
      { name: "体质健康", max: 120 },
    ],
    name: {
      rich: {
        a: {
          color: "#444",
          lineHeight: 10,
        },
      },
      formatter: (a, b) => {
        // return `{a|${a}}\n{b|${b.max}%}`;
        return `{a|${a}}`;
      },
    },
  },
  color: [
    "#7AC3CB",
    "#2478F2",
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "师德师风",
      type: "radar",
      data: [
        {
          value: [0, 0, 0, 0],
          name: "师德师风",

          label: {
            normal: {
              show: true,
              color: "#7AC3CB",
              formatter: function (params) {
                return params.value;
              },
            },
          },
          areaStyle: {
            color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
              {
                color: "#7AC3CB",
                offset: 0,
              },
              {
                color: "#7AC3CB",
                offset: 1,
              },
            ]),
          },
        },
      ],
    },
  ],
};

export const attendance_Class = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
      ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value}次</br> ${record[1].seriesName} \n ${record[1].value}次`;
    },
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["7月", "8月", "9月", "10月", "11月", "12月", "1月"],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: (value) => {
          return value.value + "次";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: (value) => {
          return value.value + "次";
        },
      },
    },
  ],
};

export const attendance_Class_studentAll = {
  title: {
    text: "体育课出勤率学校TOP10(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "体育课出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const attendance_Class_student = {
  title: {
    text: "体育课出勤率分布图（2022-2023学年）",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "体育课出勤分布图",
      type: "pie",
      radius: "70%",
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
        { value: 0, name: "高一" },
        { value: 0, name: "高二" },
        { value: 0, name: "高三" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const recess = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
      ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value}次</br> ${record[1].seriesName} \n ${record[1].value}%`;
    },
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["7月", "8月", "9月", "10月", "11月", "12月", "1月"],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: (value) => {
          return value.value + "次";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: (value) => {
          return value.value + "次";
        },
      },
    },
  ],
};

export const recessStudent = {
  title: {
    text: "大课间出勤率分布图（2022-2023学年）",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "大课间出勤率分布图",
      type: "pie",
      radius: "70%",
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
        { value: 0, name: "高一" },
        { value: 0, name: "高二" },
        { value: 0, name: "高三" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const recessStudentAll = {
  title: {
    text: "大课间出勤率学校TOP10(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const teachingPlans = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
      ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value}份</br> ${record[1].seriesName} \n ${record[1].value}份`;
    },
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["7月", "8月", "9月", "10月", "11月", "12月", "1月"],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: (value) => {
          return value.value + "份";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: (value) => {
          return value.value + "份";
        },
      },
    },
  ],
};

export const teachingPlansStudent = {
  title: {
    text: "教学教案分布图（2022-2023学年）",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}份`;
    },
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "教学教案分布图",
      type: "pie",
      radius: "70%",
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
        { value: 0, name: "高一" },
        { value: 0, name: "高二" },
        { value: 0, name: "高三" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}份`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};
export const teachingPlansStudentAll = {
  title: {
    text: "教学教案新量TOP10(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const health = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}%</br> ${record[1].seriesName} \n ${record[1].value[2]}%`;
    },
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "优秀率", "合格率"],
      ["2022年", 0, 0],
      ["2023年", 0, 0],
    ],
  },
  xAxis: { type: "category" },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        color: "#5D77F9",
        position: "top",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        color: "#84B7F9",
        position: "top",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};

export const health_student = {
  title: {
    text: "体质健康双达标分布图（2022-2023学年）",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "体质健康双达标分布图",
      type: "pie",
      radius: "70%",
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};
export const health_student_All = {
  title: {
    text: "体质健康双达标学校TOP10(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};
